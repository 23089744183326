import { is_empty } from "utils/validations";
import {
  CREATIVES_TABS,
  EDIT_CREATIVE_TABS,
  PERSONA_TABS,
  TABS,
} from "../Ads/Utils";
import {
  EXLY_MARKETING__SET_CREATOR_DOMAIN_LOADER,
  EXLY_MARKETING__REQUEST_CREATOR_DOMAIN,
  EXLY_MARKETING__SET_CREATOR_DOMAIN,
  EXLY_MARKETING__SET_CREATOR_NAMESERVERS,
  EXLY_MARKETING__SET_MARKETING_CREATIVES,
  EXLY_MARKETING__SET_MARKETING_CREATIVE_DETAILS,
  EXLY_MARKETING__ADS__CHANGE_AD_CREATION_STEP,
  EXLY_MARKETING__ADS__SET_CREATIVES,
  EXLY_MARKETING__ADS__GET_CREATIVES,
  EXLY_MARKETING__ADS__GET_CAMPAIGNS,
  EXLY_MARKETING__ADS__SET_CAMPAIGNS,
  EXLY_MARKETING__ADS__SET_API_PAYLOAD,
  EXLY_MARKETING__ADS__SET_ACTIVE_CAMPAIGN_TAB,
  EXLY_MARKETING__ADS__SET_CAMPAIGN_EXISTS,
  EXLY_MARKETING__ADS__GET_CAMPAIGN_EXISTS,
  EXLY_MARKETING__ADS__SET_CTA,
  EXLY_MARKETING__ADS__SET_ACTIVE_CREATIVE_TAB,
  EXLY_MARKETING__ADS__SET_SELECTED_CREATIVES,
  EXLY_MARKETING__ADS__GET_CONSUMED_CREATIVES,
  EXLY_MARKETING__ADS__GET_PERSONAS,
  EXLY_MARKETING__ADS__SET_PERSONAS,
  EXLY_MARKETING__ADS__SET_ACTIVE_PERSONA_TAB,
  EXLY_MARKETING__ADS__SET_ACTIVE_EDIT_CREATIVE_TAB,
  EXLY_MARKETING__ADS__SET_CAMPAIGN_UUID,
  EXLY_MARKETING__ADS__RESET_STATE,
  EXLY_MARKETING__ADS__SET_PERSONA_REACH,
  EXLY_MARKETING__ADS__SET_TEMPLATE_CONTENT_EDITED,
  EXLY_MARKETING__ADS__SET_ERROR_MODAL,
  EXLY_MARKETING__ADS__SET_CREATIVE_AD_CONTENT,
  EXLY_MARKETING__ADS__SET_PROCESSING,
  EXLY_MARKETING__ADS__REFRESH_CAMPAIGN_STATS,
  EXLY_MARKETING_ADS_SET_VALIDATIONS,
} from "./actions";

const initialState = {
  loading: false,
  domainDetails: null,
  nameservers: null,
  marketing_creatives: null,
  marketing_creative_details: null,
  ads: {
    processing: true,
    refresh_campaign_uuid: null,
    campaign_exists: false,
    campaign_uuid: null,
    ad_creation_step: null,
    CTA: {},
    creatives: {},
    creative_ad_content: {},
    campaigns: {},
    personas: {},
    api_payload: {},
    active_campaign_tab: TABS["4"].value,
    active_creative_tab: CREATIVES_TABS.basic.slug,
    active_edit_creative_tab: EDIT_CREATIVE_TABS.template_content.slug,
    active_persona_tab: PERSONA_TABS.default.slug,
    selected_creatives: [],
    persona_reach: "0",
    template_content_edited: false,
    show_error_modal: false,
    errorObject: {},
  },
};

const reducer = (
  state = initialState,
  { type, payload, nameservers, loading }
) => {
  switch (type) {
    case EXLY_MARKETING__SET_CREATOR_DOMAIN_LOADER: {
      return { ...state, loading: loading };
    }
    case EXLY_MARKETING__REQUEST_CREATOR_DOMAIN: {
      return { ...state, loading: true };
    }
    case EXLY_MARKETING__SET_CREATOR_DOMAIN: {
      return {
        ...state,
        domainDetails: payload,
        nameservers: payload?.custom_domains?.[0]?.name_server_list,
        loading: false,
      };
    }
    case EXLY_MARKETING__SET_CREATOR_NAMESERVERS: {
      return { ...state, nameservers: nameservers, loading: false };
    }
    case EXLY_MARKETING__SET_MARKETING_CREATIVES: {
      return { ...state, marketing_creatives: payload };
    }
    case EXLY_MARKETING__SET_MARKETING_CREATIVE_DETAILS: {
      return {
        ...state,
        marketing_creative_details: {
          ...(state?.marketing_creative_details || {}),
          [payload?.creative_uuid]: {
            data: payload?.data,
            loaded: true,
          },
        },
      };
    }
    case EXLY_MARKETING__ADS__CHANGE_AD_CREATION_STEP: {
      return { ...state, ads: { ...state.ads, ad_creation_step: payload } };
    }
    case EXLY_MARKETING__ADS__SET_CAMPAIGN_UUID: {
      return { ...state, ads: { ...state.ads, campaign_uuid: payload } };
    }
    case EXLY_MARKETING__ADS__REFRESH_CAMPAIGN_STATS: {
      return {
        ...state,
        ads: { ...state.ads, refresh_campaign_uuid: payload },
      };
    }
    case EXLY_MARKETING__ADS__RESET_STATE: {
      return {
        ...state,
        ads: {
          ...state.ads,
          ad_creation_step: null,
          CTA: {},
          api_payload: {},
          selected_creatives: [],
          campaign_uuid: null,
          active_persona_tab: PERSONA_TABS.default.slug,
          persona_reach: "0",
        },
      };
    }
    case EXLY_MARKETING__ADS__GET_CREATIVES: {
      return { ...state, ads: { ...state.ads, processing: true } };
    }
    case EXLY_MARKETING__ADS__GET_CONSUMED_CREATIVES: {
      return { ...state, ads: { ...state.ads, processing: true } };
    }
    case EXLY_MARKETING__ADS__SET_CREATIVES: {
      let creatives = Object.assign(state.ads.creatives, payload);
      return {
        ...state,
        ads: { ...state.ads, creatives: creatives, processing: false },
      };
    }
    case EXLY_MARKETING__ADS__GET_CAMPAIGNS: {
      return { ...state, ads: { ...state.ads, processing: true } };
    }
    case EXLY_MARKETING__ADS__SET_CAMPAIGNS: {
      let campaigns = Object.assign(state.ads.campaigns, payload);
      return {
        ...state,
        ads: {
          ...state.ads,
          campaigns: campaigns,
          processing: false,
          refresh_campaign_uuid: null,
        },
      };
    }
    case EXLY_MARKETING__ADS__SET_PERSONAS: {
      let personas = Object.assign(state.ads.personas, payload);
      return {
        ...state,
        ads: { ...state.ads, personas: personas, processing: false },
      };
    }
    case EXLY_MARKETING__ADS__GET_CAMPAIGN_EXISTS: {
      return { ...state, ads: { ...state.ads, processing: true } };
    }
    case EXLY_MARKETING__ADS__SET_CAMPAIGN_EXISTS: {
      return {
        ...state,
        ads: { ...state.ads, campaign_exists: payload, processing: false },
      };
    }
    case EXLY_MARKETING__ADS__SET_CREATIVE_AD_CONTENT: {
      return { ...state, ads: { ...state.ads, creative_ad_content: payload } };
    }
    case EXLY_MARKETING__ADS__SET_ACTIVE_CAMPAIGN_TAB: {
      return { ...state, ads: { ...state.ads, active_campaign_tab: payload } };
    }
    case EXLY_MARKETING__ADS__SET_ACTIVE_CREATIVE_TAB: {
      return { ...state, ads: { ...state.ads, active_creative_tab: payload } };
    }
    case EXLY_MARKETING__ADS__SET_ACTIVE_EDIT_CREATIVE_TAB: {
      return {
        ...state,
        ads: { ...state.ads, active_edit_creative_tab: payload },
      };
    }
    case EXLY_MARKETING__ADS__SET_ACTIVE_PERSONA_TAB: {
      return { ...state, ads: { ...state.ads, active_persona_tab: payload } };
    }
    case EXLY_MARKETING__ADS__SET_PERSONA_REACH: {
      return { ...state, ads: { ...state.ads, persona_reach: payload } };
    }
    case EXLY_MARKETING__ADS__SET_CTA: {
      return { ...state, ads: { ...state.ads, CTA: payload } };
    }
    case EXLY_MARKETING__ADS__SET_TEMPLATE_CONTENT_EDITED: {
      return {
        ...state,
        ads: { ...state.ads, template_content_edited: payload },
      };
    }
    case EXLY_MARKETING__ADS__SET_ERROR_MODAL: {
      return { ...state, ads: { ...state.ads, show_error_modal: payload } };
    }
    case EXLY_MARKETING__ADS__SET_SELECTED_CREATIVES: {
      return { ...state, ads: { ...state.ads, selected_creatives: payload } };
    }
    case EXLY_MARKETING__ADS__GET_PERSONAS: {
      return { ...state, ads: { ...state.ads, processing: true } };
    }
    case EXLY_MARKETING__ADS__SET_PROCESSING: {
      return { ...state, ads: { ...state.ads, processing: payload } };
    }
    case EXLY_MARKETING__ADS__SET_API_PAYLOAD: {
      let params = state.ads.api_payload;
      Object.assign(params, payload);
      return {
        ...state,
        ads: { ...state.ads, api_payload: is_empty(payload) ? {} : params },
      };
    }
    case EXLY_MARKETING_ADS_SET_VALIDATIONS: {
      return { ...state, ads: { ...state.ads, errorObject: payload } };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
