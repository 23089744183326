export const EXLY_MARKETING__SET_CREATOR_DOMAIN_LOADER =
  "@EXLY_MARKETING/SET_CREATOR_DOMAIN_LOADER";
export const EXLY_MARKETING__REQUEST_CREATOR_DOMAIN =
  "@EXLY_MARKETING/REQUEST_CREATOR_DOMAIN";
export const EXLY_MARKETING__SET_CREATOR_DOMAIN =
  "@EXLY_MARKETING/SET_CREATOR_DOMAIN";
export const EXLY_MARKETING__SET_CREATOR_NAMESERVERS =
  "@EXLY_MARKETING/SET_CREATOR_NAMESERVERS";
export const EXLY_MARKETING__GET_MARKETING_CREATIVES =
  "@EXLY_MARKETING/GET_MARKETING_CREATIVES";
export const EXLY_MARKETING__SET_MARKETING_CREATIVES =
  "@EXLY_MARKETING/SET_MARKETING_CREATIVES";
export const EXLY_MARKETING__GET_MARKETING_CREATIVE_DETAILS =
  "@EXLY_MARKETING/GET_MARKETING_CREATIVE_DETAILS";
export const EXLY_MARKETING__SET_MARKETING_CREATIVE_DETAILS =
  "@EXLY_MARKETING/SET_MARKETING_CREATIVE_DETAILS";
export const EXLY_MARKETING__UPDATE_MARKETING_CREATIVE =
  "@EXLY_MARKETING/UPDATE_MARKETING_CREATIVE";
export const EXLY_MARKETING__CREATE_MARKETING_CREATIVE =
  "@EXLY_MARKETING/CREATE_MARKETING_CREATIVE";
export const EXLY_MARKETING__DELETE_MARKETING_CREATIVE =
  "@EXLY_MARKETING/DELETE_MARKETING_CREATIVE";
export const EXLY_MARKETING__ADS__CHANGE_AD_CREATION_STEP =
  "@EXLY_MARKETING/ADS/CHANGE_AD_CREATION_STEP";
export const EXLY_MARKETING__ADS__GET_CREATIVES =
  "@EXLY_MARKETING/ADS/GET_CREATIVES";
export const EXLY_MARKETING__ADS__SET_CREATIVES =
  "@EXLY_MARKETING/ADS/SET_CREATIVES";
export const EXLY_MARKETING__ADS__GET_CAMPAIGNS =
  "@EXLY_MARKETING/ADS/GET_CAMPAIGNS";
export const EXLY_MARKETING__ADS__SET_CAMPAIGNS =
  "@EXLY_MARKETING/ADS/SET_CAMPAIGNS";
export const EXLY_MARKETING__ADS__SET_API_PAYLOAD =
  "@EXLY_MARKETING/ADS/SET_API_PAYLOAD";
export const EXLY_MARKETING__ADS__SET_ACTIVE_CAMPAIGN_TAB =
  "@EXLY_MARKETING/ADS/SET_ACTIVE_CAMPAIGN_TAB";
export const EXLY_MARKETING__ADS__SET_CAMPAIGN_EXISTS =
  "@EXLY_MARKETING/ADS/SET_CAMPAIGN_EXISTS";
export const EXLY_MARKETING__ADS__GET_CAMPAIGN_EXISTS =
  "@EXLY_MARKETING/ADS/GET_CAMPAIGN_EXISTS";
export const EXLY_MARKETING__ADS__SET_CTA = "@EXLY_MARKETING/ADS/SET_CTA";
export const EXLY_MARKETING__ADS__SET_ACTIVE_CREATIVE_TAB =
  "@EXLY_MARKETING/ADS/SET_ACTIVE_CREATIVE_TAB";
export const EXLY_MARKETING__ADS__SET_SELECTED_CREATIVES =
  "@EXLY_MARKETING/ADS/SET_SELECTED_CREATIVES";
export const EXLY_MARKETING__ADS__GET_CONSUMED_CREATIVES =
  "@EXLY_MARKETING/ADS/GET_CONSUMED_CREATIVES";
export const EXLY_MARKETING__ADS__SET_ACTIVE_PERSONA_TAB =
  "@EXLY_MARKETING/ADS/SET_ACTIVE_PERSONA_TAB";
export const EXLY_MARKETING__ADS__GET_PERSONAS =
  "@EXLY_MARKETING/ADS/GET_PERSONAS";
export const EXLY_MARKETING__ADS__SET_PERSONAS =
  "@EXLY_MARKETING/ADS/SET_PERSONAS";
export const EXLY_MARKETING__ADS__GET_FB_LOCATIONS =
  "@EXLY_MARKETING/ADS/GET_FB_LOCATIONS";
export const EXLY_MARKETING__ADS__GET_FB_INTERESTS =
  "@EXLY_MARKETING/ADS/INTERESTS";
export const EXLY_MARKETING__ADS__SET_ACTIVE_EDIT_CREATIVE_TAB =
  "@EXLY_MARKETING/ADS/SET_ACTIVE_EDIT_CREATIVE_TAB";
export const EXLY_MARKETING__ADS__SET_CAMPAIGN_UUID =
  "@EXLY_MARKETING/ADS/SET_CAMPAIGN_UUID";
export const EXLY_MARKETING__ADS__RESET_STATE =
  "@EXLY_MARKETING/ADS/RESET_STATE";
export const EXLY_MARKETING__ADS__GET_CAMPAIGN_DETAIL =
  "@EXLY_MARKETING/ADS/GET_CAMPAIGN_DETAIL";
export const EXLY_MARKETING__ADS__SET_CREATIVE_AD_CONTENT =
  "@EXLY_MARKETING/ADS/SET_CREATIVE_AD_CONTENT";
export const EXLY_MARKETING__ADS__SET_PERSONA_REACH =
  "@EXLY_MARKETING/ADS/SET_PERSONA_REACH";
export const EXLY_MARKETING__ADS__SET_TEMPLATE_CONTENT_EDITED =
  "@EXLY_MARKETING/ADS/SET_TEMPLATE_CONTENT_EDITED";
export const EXLY_MARKETING__ADS__SET_ERROR_MODAL =
  "@EXLY_MARKETING/ADS/SET_ERROR_MODAL";
export const EXLY_MARKETING__ADS__SET_PROCESSING =
  "@EXLY_MARKETING/ADS/SET_PROCESSING";
export const EXLY_MARKETING__ADS__REFRESH_CAMPAIGN_STATS =
  "@EXLY_MARKETING/ADS/REFRESH_CAMPAIGN_STATS";
export const EXLY_MARKETING_ADS_SET_VALIDATIONS =
  "@EXLY_MARKETING_ADS_SET_VALIDATIONS";
